// src/components/LanguageDropdown.js
import React, { useState } from 'react';

const LanguageDropdown = () => {
  const [selectedLanguage, setSelectedLanguage] = useState('English'); 

  const languages = ['English', 'Spanish', 'French', 'German', 'Hindi'];

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  return (

    <div className="language-dropdown">
      <button className="dropdown-toggle">{selectedLanguage}</button>
      <ul className="dropdown-menu">
        {languages.map((language, index) => (
          <li key={index} onClick={() => handleLanguageChange(language)}>
            {language}
          </li>
        ))}
      </ul>
    </div>
 
  );
};

export default LanguageDropdown;
