// src/components/Navbar.js
import React, { useState } from 'react';
import LanguageDropdown from './LanguageDropdown'; 
import { FaCaretDown } from "react-icons/fa";
import './Navbar.css';

const Navbar = () => {
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const [isAppDropdownOpen, setIsAppDropdownOpen] = useState(false);

  const handleLanguageMouseEnter = () => {
    setIsLanguageDropdownOpen(true);
  };

  const handleLanguageMouseLeave = () => {
    setIsLanguageDropdownOpen(false);
  };

  const handleAppMouseEnter = () => {
    setIsAppDropdownOpen(true);
  };

  const handleAppMouseLeave = () => {
    setIsAppDropdownOpen(false);
  };

  return (
    <>
      <nav className="navbar">
      
      <div className="navbar-left">
        <div className="navbar-logo">
          {/* Bumble Logo */}
          <img src="/img/logo-heading.png" alt="Bumble Logo" />
        </div>
        <div
          className="navbar-language"
          onMouseEnter={handleLanguageMouseEnter}
          onMouseLeave={handleLanguageMouseLeave}
        >
          Select Language <FaCaretDown />
          {isLanguageDropdownOpen && <LanguageDropdown />}
        </div>
      </div>
      <div className="navbar-right">
        <div className="navbar-links">
          <ul>
          <li className="nav-button">The Shop </li>
            <li
              className="nav-button"
              onMouseEnter={handleAppMouseEnter}
              onMouseLeave={handleAppMouseLeave}
            >
                
              The App <FaCaretDown />
              {isAppDropdownOpen && (
                <ul className="app-dropdown">
                  <li>Date</li>
                  <li>BFF</li>
                  <li>Bizz</li>
                </ul>
              )}
            </li>
            <li className="nav-button">Ambassadors</li>
            <li className="nav-button">Events</li>
            <li className="nav-button">The Buzz</li>
            <li className="nav-button">About</li>
            
          </ul>
        </div>
      </div>
    </nav>
    <div className="login-signup-container">
            <h1>Make the first move</h1>
            <p>Start meeting new people in your area! If you already have an account, sign in to use Hideout on the web.</p>
        <div className="login-signup">
            <a href="https://app.hideoutdating.com/"><button className="join-btn">Join</button></a>
            <a href="https://app.hideoutdating.com/"><button className="sign-btn">Sign In</button></a>
        </div>
      </div>
      <div className="banner">
        <img src="/img/banner.jpg" alt="Banner" />
      </div>
    </>
  );
};
export default Navbar;
