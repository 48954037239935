import React from 'react'
import './footer.css'
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebookSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";

function Footer() {
  return (
    <div className='footerContainer'>
       <a href="/">Follow @Hideout</a>
       <img src="/img/footer1.png" alt="" />

       <div className='socialIcon'>
       <RiInstagramFill />
       <FaFacebookSquare />
       <FaTwitter />
       </div>

      <p> © 2024 Hideout | All Rights Reserved </p>
    </div>


  )
}

export default Footer