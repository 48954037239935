import React from "react";
import "./promo.css";

function Promo() {
  return (
    <div className="promo-container">
      <div className="promo-img">
        <img src="img/promo.png" alt="promo-img" />
      </div>
      <div className="promo-text-container">
        <div className="promo-content">
          <h1>Why Bumble?</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni velit
            voluptatem vel dignissimos quisquam pariatur sapiente eos ducimus
            ab. Incidunt veniam at delectus cum nam quo qui repudiandae
            provident. Repudiandae?
          </p>
          <div className="promo-btn">
            <button
              onClick={() => (window.location.href = "/link/to/learn/more")}
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Promo;
