// src/components/SliderBanner.js
import React, { useState } from 'react';
import './SliderBanner.css';

function SliderBanner() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const images = [
    "/img/slider3.png",
    "/img/slider2.png",
    "/img/slider1.png"
  ];

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === images.length - 1 ? 0 : prevSlide + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? images.length - 1 : prevSlide - 1));
  };

  return (
    <div className="slider-banner">
      <div className="slider-slide" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
        {images.map((image, index) => (
          <img key={index} src={image} alt={`Slider ${index + 1}`} />
        ))}
      </div>
      <button className="prev-btn" onClick={prevSlide}>❮</button>
      <button className="next-btn" onClick={nextSlide}>❯</button>
    </div>
  );
}

export default SliderBanner;
