// src/App.js
import React from 'react';
import Navbar from './components/Navbar';
import Testimonials from './components/Testimonials';
import Promo from './components/Promo';
import SliderBanner from './components/SliderBanner';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Testimonials />
      <Promo />
      <SliderBanner />
      <Footer />
      {/* Other components and content */}
    </div>
  );
}

export default App;
