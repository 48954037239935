// src/components/Testimonials.js
import React from "react";
import "./Testimonials.css";

function Testimonials() {
  return (
    <div className="Test-container">
      <div className="test-dev">
        <h1>We’re not just for dating anymore</h1>
      </div>
      <div className="test-img-container">
        <div className="test-img">
          <img src="img/5.jpg" alt="" />
          <div className="test-img-p">
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odit
              facilis neque ipsum debitis amet architecto praesentium recusandae
              inventore temporibus culpa!
            </p>
          </div>

          <div className="test-btn">
            <button
              onClick={() => (window.location.href = "/link/to/learn/more")}
            >
              Learn More
            </button>
          </div>
        </div>
        <div className="test-img">
          <img src="img/3.jpg" alt="" />
          <div className="test-img-p">
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi
              tenetur corrupti ex repellendus tempora officiis natus fugiat
              necessitatibus quae dolorum!
            </p>
          </div>

          <div className="test-btn">
            <button
              onClick={() => (window.location.href = "/link/to/learn/more")}
            >
              Learn More
            </button>
          </div>
        </div>
        <div className="test-img">
          <img src="img/4.jpg" alt="" />
          <div className="test-img-p">
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quod
              eligendi eius impedit cumque aspernatur amet ullam voluptas et
              velit eum?
            </p>
          </div>
          <div className="test-btn">
            <button
              onClick={() => (window.location.href = "/link/to/learn/more")}
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
